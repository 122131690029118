import { ICognitoUserAttributeData } from "amazon-cognito-identity-js";

export function userAttributesToObject<T = any>(
  userAttributes: ICognitoUserAttributeData[]
) {
  return userAttributes.reduce((obj, attr) => {
    return {
      ...obj,
      [attr.Name]: attr.Value,
    };
  }, {}) as T;
}
